<template>
    <table id="stock" class="table table-sm table-secondary" >
        <thead>
        <tr>
            <th>Branch</th>
            <th v-if="is_customer">Qty.</th>
            <th>Stock</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>San Jose</td>
            <td v-if="is_customer" id="sj"><input class="form-control" type="text" v-model="branch.sj.qty" /></td>
            <td><span v-if="is_customer">of </span>{{branch.sj.stock}}</td>
        </tr>
        <tr>
            <td>Los Angeles</td>
            <td v-if="is_customer" id="la"><input class="form-control" type="text" v-model="branch.la.qty" /></td>
            <td><span v-if="is_customer">of </span>{{branch.la.stock}}</td>
        </tr>
        <tr>
            <td>Miami</td>
            <td v-if="is_customer" id="mi"><input class="form-control" type="text" v-model="branch.mi.qty" /></td>
            <td><span v-if="is_customer">of </span>{{branch.mi.stock}}</td>
        </tr>
        <!-- <tr>
            <td>Georgia</td>
            <td><input class="form-control" type="text" v-model="branch.ge.qty" /></td>
            <td>of {{branch.ge.stock}}</td>
        </tr> -->
        <tr>
            <td>New Jersey</td>
            <td v-if="is_customer" id="nj"><input class="form-control" type="text" v-model="branch.nj.qty" /></td>
            <td><span v-if="is_customer">of </span>{{branch.nj.stock}}</td>
        </tr>
        <tr>
            <td>Chicago</td>
            <td v-if="is_customer" id="il"><input class="form-control" type="text" v-model="branch.il.qty" /></td>
            <td><span v-if="is_customer">of </span>{{branch.il.stock}}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import { ref } from "vue"

export default {
 name: 'StockCart',
 props: ['branch', 'is_customer'],
 setup(props, context) { 
     const is_customer = ref(props.is_customer)

     return { is_customer }
 }

}
</script>

<style scoped>

#stock {width:100%; font-size:12px;}
#stock td, #stock th {padding-left:8px; padding-right:5px;}
#stock input {
    width:40px;
    padding:5px;
    height:25px;
    font-size:12px;
}
</style>